
.nav-container {
    height: var(--nav-height);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(255,255,255, 0.85);
}

.inner-nav-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.title-container {
    width: 30%;
    max-width: 300px;
    margin: auto;
}

.title-container:hover {
    cursor:pointer;
}

.nav-container ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin: auto;
}

.nav-element {
    padding : 0 1rem;
    text-align: center;
}

.nav-element button {
    color: var(--green);
    max-width: 150px;
    font-weight: bold;
    text-align: center;
}

.nav-element button:hover {
    text-decoration: underline;
    text-underline-position: under;
}

.nav-element-active button {
    color: var(--yellow)
}

.nav-full {
    display: inline;
}
.nav-short {
    display: none;
}

@media (max-width: 1000px) {

    .nav-full {
        display: none;
    }
    .nav-short {
        display: inline;
    }
}

@media (max-width: 800px) {

    .nav-element button {
        font-size: 0.75rem;
    }

    .nav-element {
        padding : 0 0.5rem;
        text-align: center;
    }
}

@media (max-width: 500px) {

    .nav-element button {
        font-size: 0.4rem;
    }
}


