@import url(https://fonts.googleapis.com/css?family=Baloo+Paaji+2:1000,600,400&display=swap);
/* Layout */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --nav-height: 70px;
}

.nav-area {
    height: var(--nav-height);
}

img {
    max-width: 100%;
    max-height: 100%;
    margin: auto auto;
    align-self: center;
}

.project-container {
    display: flex;
    justify-content: space-between;
    padding: var(--nav-height) 5%;
}

.project-text {
    width: 37.5%;
    padding-top: 5rem;
}

.project-paragraph {
    margin-top: 1rem;
}

.project-media {
    width: 60%;
    position: relative;
}

.media-container {
    position: relative;
    width: 100%;
    padding-bottom: 65%;
}

.inner-media-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-container {
    height: 100%;
    width: 100%;
    display: flex;
}

.carousel-container {
    height: 100px;
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) {
    .project-container {
        flex-direction: column;
    }

    .project-text, .project-media {
        width: 100%;
    }

    .project-media {
        order: 1;
    }

    .project-text {
        order: 2;
        padding-top: 0;
        margin:1rem auto;
    }

    .project-text h2 {
        margin-top: 2.5%;
    }
}


/* Typography */

* {
    font-family: 'Baloo Paaji 2', 'Calibri';
    --background: #F1F7F5;
    --yellow: #FFDB6A;
    --green: #1C8296;
}

h2 {
    font-size: 2.5rem;
    font-weight: 900;
    margin: 2.5rem auto;
    text-transform: uppercase;
    line-height: 2.5rem;
    text-align: center;
}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: auto;
    line-height: 2rem;
}

p {
    font-size: 1rem;
    font-weight: 400;
    text-align: justify;
    line-height: 1.25rem;
}

button {
    border: none;
    background-color: transparent;
    font: inherit;
}

button:hover {
    text-decoration: underline;
    text-underline-position: under;
    cursor:pointer;
}

button:hover, button:focus {
    outline: none;
}

.regular-project-container {
    background-color: var(--background);
    color: rgba(0, 0, 0, 0.8)
}

.regular-project-container h2,
.regular-project-container button {
    color: var(--green);
}

.goofy-project-container {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
}

.goofy-project-container h2,
.goofy-project-container button {
    color: var(--yellow);
}

.contact-container {
    margin : 0 auto;
    text-align: center;
}

.contact-container h3 {
    margin: 2rem auto;
}

.project-text button {
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    font-size: 2.5rem;
    justify-content: center;
}

@media (max-width: 700px) {
    h2 {
        font-size: 2rem;
        line-height: 2rem;
    }

    h3 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    p {
        font-size: 0.75rem;
        line-height: 1rem;
    }

    .project-text button {
        font-size: 2rem;
        letter-spacing: 3px;
    }
}




.nav-container {
    height: var(--nav-height);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(255,255,255, 0.85);
}

.inner-nav-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.title-container {
    width: 30%;
    max-width: 300px;
    margin: auto;
}

.title-container:hover {
    cursor:pointer;
}

.nav-container ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin: auto;
}

.nav-element {
    padding : 0 1rem;
    text-align: center;
}

.nav-element button {
    color: var(--green);
    max-width: 150px;
    font-weight: bold;
    text-align: center;
}

.nav-element button:hover {
    text-decoration: underline;
    text-underline-position: under;
}

.nav-element-active button {
    color: var(--yellow)
}

.nav-full {
    display: inline;
}
.nav-short {
    display: none;
}

@media (max-width: 1000px) {

    .nav-full {
        display: none;
    }
    .nav-short {
        display: inline;
    }
}

@media (max-width: 800px) {

    .nav-element button {
        font-size: 0.75rem;
    }

    .nav-element {
        padding : 0 0.5rem;
        text-align: center;
    }
}

@media (max-width: 500px) {

    .nav-element button {
        font-size: 0.4rem;
    }
}



.slider-container {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image-container {
    padding: 10px 10px;
    height: 100%;
    width: 100%;
    display: flex;
}

.hidden-slider-image-container {
    height: 100%;
    width: 0;
}

.slider-image-container:hover {
    cursor: pointer;
}

.arrow-container {
    flex: 0 0 50px;
    height: 50px;
    padding: 0 10px;
}

.arrow-container img {
    width: 100%;
    height: 100%;
    background: transparent;
}

@media (max-width: 700px) {

    .slider-image-container {
        padding: 10px 5px;
    }

    .arrow-container {
        flex: 0 0 30px;
        height: 50px;
        padding: 0 5px;
    }
}
.player-wrapper {
    position: relative;
    width:100%;
    padding-top: 65%;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  
