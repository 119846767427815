.slider-container {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image-container {
    padding: 10px 10px;
    height: 100%;
    width: 100%;
    display: flex;
}

.hidden-slider-image-container {
    height: 100%;
    width: 0;
}

.slider-image-container:hover {
    cursor: pointer;
}

.arrow-container {
    flex: 0 0 50px;
    height: 50px;
    padding: 0 10px;
}

.arrow-container img {
    width: 100%;
    height: 100%;
    background: transparent;
}

@media (max-width: 700px) {

    .slider-image-container {
        padding: 10px 5px;
    }

    .arrow-container {
        flex: 0 0 30px;
        height: 50px;
        padding: 0 5px;
    }
}